<template>
  <div class="chat-page">
    <CreekChatPanel />
  </div>
</template>

<script>

import CreekChatPanel from '@creek-website-toolkit/components/panels/ChatPanel.vue'

export default {
  name: 'ChatPage',
  components: {
    CreekChatPanel
  },
  data(){
    return {
      updateChatSize: null
    }
  },
  methods: {
    updateChatSize(){



      const els = document.getElementsByClassName('creek-chat-panel')

      for (let el of els){
        el.style.top = '100px'
      }

    }
  },
  created(){

  },
  beforeDestroy(){

  }
}

</script>
